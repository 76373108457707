html {
  scroll-behavior: smooth;
  -webkit-print-color-adjust: exact;
  -webkit-filter: opacity(1);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Inter", sans-serif;
  color: black;
  margin: 0;
  padding: 0;
  /* overflow: hidden; revisar esto */
}

a {
  text-decoration: none;
}

input,
select,
textarea {
  font-family: "Inter", sans-serif;
  color: black;
  font-size: 14px;
}

* {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}
* ::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}